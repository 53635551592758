import axios from 'axios';
import { errorMessage } from '../meta/NotificationService';

interface ICreateAccountProps {
  company: string;
  vat: string;
  phonenumber: string;
  website: string;
  currency_name: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  country_id: string;
  title: string;
  firstname: string;
  lastname: string;
  email: string;
  password: string;
}

export const createAccount = async ({
  company,
  vat,
  phonenumber,
  website,
  currency_name,
  address,
  city,
  state,
  zip,
  country_id,
  title,
  firstname,
  lastname,
  email,
  password,
}: ICreateAccountProps) => {
  try {
    let formData: any = new FormData();

    formData.append('company', company);
    formData.append('vat', vat);
    formData.append('phonenumber', phonenumber);
    formData.append('website', website);
    formData.append('currency_name', currency_name);
    formData.append('address', address);
    formData.append('city', city);
    formData.append('state', state);
    formData.append('zip', zip);
    formData.append('country_id', country_id);
    formData.append('title', title);
    formData.append('firstname', firstname);
    formData.append('lastname', lastname);
    formData.append('email', email);
    formData.append('password', password);

    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/api/create_account`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      errorMessage('Something went Wrong!');
      return null;
    }
  }
};
