import './loading.component.css';

export const LoadingComponent = () => {
  return (
    <div className="loading-screen" id={'loading-screen-identifier'}>
      <div className={'video'} style={{ textAlign: 'center' }}>
        {/* <img className="logo-loading" src="/images/logo-dark.webp" alt="logo"></img> */}
        Please be patient while processing the data
      </div>
      <div className="loading-dots">
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
      </div>
    </div>
  );
};
