import { useState } from 'react';
import { CreateClientForm } from '../components/forms/create-client-form.component';
import { CreateContactForm } from '../components/forms/create-contact-form.component';
import { createAccount } from '../api/leadService';
import { errorMessage, successMessage } from '../meta/NotificationService';
import { LoadingComponent } from '../components/LoadingComponent/loading.component';

export const STEPS = {
  COMPANY_CREATE: 1,
  CONTACT_CREATE: 2,
  THANK_YOU: 3,
};

export const CreateClientPage = () => {
  const [step, setStep] = useState(STEPS.COMPANY_CREATE);
  const [companyData, setCompanyData] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);

  const changeStep = (currentStepId: number, nextStepId: number, dataObject: any) => {
    setStep(nextStepId);

    switch (currentStepId) {
      case STEPS.COMPANY_CREATE:
        setCompanyData(dataObject);
        break;

      case STEPS.CONTACT_CREATE:
        const leadDetails = {
          company: companyData?.company ? companyData?.company : '',
          vat: companyData?.vatNumber ? companyData?.vatNumber : '',
          phonenumber: companyData?.phoneNumber ? companyData?.phoneNumber : '',
          website: companyData?.website ? companyData?.website : '',
          currency_name: companyData?.currency?.value ? companyData?.currency?.value : '',
          address: companyData?.address ? companyData?.address : '',
          city: companyData?.city ? companyData?.city : '',
          state: companyData?.state ? companyData?.state : '',
          zip: companyData?.zipCode ? companyData?.zipCode : '',
          country_id: companyData?.country?.code ? companyData?.country?.code : '',
          title: dataObject?.position ? dataObject?.position : '',
          firstname: dataObject?.firstName ? dataObject?.firstName : '',
          lastname: dataObject?.lastName ? dataObject?.lastName : '',
          email: dataObject?.email ? dataObject?.email : '',
          password: dataObject?.password ? dataObject?.password : '',
        };

        setIsLoading(true);
        createAccount(leadDetails).then((data) => {
          try {
            if (data?.success) {
              setStep(STEPS.COMPANY_CREATE);
              setCompanyData({});

              successMessage('Request Submitted!');
            } else {
              if (data?.message && data?.message.length > 0) {
                setStep(STEPS.CONTACT_CREATE);

                data?.message.forEach(function (errorMsg: any, index: string) {
                  errorMessage(errorMsg);
                });
              } else {
                setStep(STEPS.CONTACT_CREATE);
                errorMessage('Something went Wrong!');
              }
            }
          } catch (error) {
            setStep(STEPS.CONTACT_CREATE);
            errorMessage('Something went Wrong!');
          }

          setIsLoading(false);
        });

        break;

      default:
        return <h1>default</h1>;
    }
  };

  return (
    <>
      {step === STEPS.COMPANY_CREATE && <CreateClientForm changeStep={changeStep} />}
      {step === STEPS.CONTACT_CREATE && <CreateContactForm changeStep={changeStep} />}
      {isLoading && <LoadingComponent />}
    </>
  );
};
