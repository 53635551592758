import { useForm, Controller } from 'react-hook-form';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import styled from 'styled-components';

import './form.component.css';
import { STEPS } from '../../pages/create-client-page';

interface IFormInputs {
  firstName: string;
  lastName: string;
  position: string;
  email: string;
  password: string;
}

const schema = yup
  .object({
    firstName: yup.string().required('This field is required.'),
    lastName: yup.string().required('This field is required.'),
    position: yup.string().nullable(),
    email: yup.string().email().required('This field is required.'),
    password: yup.string().required('This field is required.'),
  })
  .required();

export const CreateContactForm = ({ changeStep }: any) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const onSubmit = (data: IFormInputs) => {
    changeStep(STEPS.CONTACT_CREATE, STEPS.THANK_YOU, data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* First Name */}
      <InputDiv>
        <InputLabel>First Name</InputLabel>
        <input {...register('firstName')} />
        {errors?.firstName && <InputError>{errors.firstName.message}</InputError>}
      </InputDiv>

      {/* Last Name */}
      <InputDiv>
        <InputLabel>Last Name</InputLabel>
        <input {...register('lastName')} />
        {errors?.lastName && <InputError>{errors.lastName.message}</InputError>}
      </InputDiv>

      {/* Position */}
      <InputDiv>
        <InputLabel>Position</InputLabel>
        <input {...register('position')} />
        {errors?.position && <InputError>{errors.position.message}</InputError>}
      </InputDiv>

      {/* Email */}
      <InputDiv>
        <InputLabel>Email</InputLabel>
        <input {...register('email')} />
        {errors?.email && <InputError>{errors.email.message}</InputError>}
      </InputDiv>

      {/* Password */}
      <InputDiv>
        <InputLabel>Password</InputLabel>
        <input {...register('password')} type="password" />
        {errors?.password && <InputError>{errors.password.message}</InputError>}
      </InputDiv>

      <div className="row">
        <div className="column-2">
          {/* <input
            type="button"
            value="Back"
            onClick={() => {
              changeStep(STEPS.CONTACT_CREATE, STEPS.COMPANY_CREATE, getValues);
            }}
          /> */}
        </div>
        <div className="column-2">
          <input type="submit" />
        </div>
      </div>
    </form>
  );
};

const InputDiv = styled.div`
  padding: 5px;
`;

const InputLabel = styled.p`
  color: black;
  margin-top: 8px;
  margin-bottom: 1px;
  font-weight: 500;
  font-size: 15px;
`;

const InputError = styled.p`
  color: red;
  margin-top: 0px;
  margin-bottom: 0px;
  &:before {
    display: inline;
    content: '⚠ ';
  }
`;
