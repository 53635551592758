import { toast } from 'react-toastify';

const notificationConfig: any = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'colored',
};

export const successMessage = (message: string) => {
  toast.success(message, notificationConfig);
};

export const errorMessage = (message: string) => {
  toast.error(message, notificationConfig);
};
