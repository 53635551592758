import { useForm, Controller } from 'react-hook-form';
import ReactSelect from 'react-select';
import PhoneInput from 'react-phone-number-input';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { countryOptions } from '../../data/country_list';

import styled from 'styled-components';

import { STEPS } from '../../pages/create-client-page';

import './form.component.css';
import 'react-phone-number-input/style.css';
import { useState } from 'react';

interface IFormInputs {
  company: string;
  vatNumber: string;
  phoneNumber: string;
  website: string;
  currency: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  country: object;
}

const schema = yup
  .object({
    company: yup.string().required('This field is required.'),
    vatNumber: yup.string().nullable(),
    phoneNumber: yup.string().required('This field is required.'),
    website: yup.string().nullable(),
    currency: yup.object().nullable(),
    address: yup.string().nullable(),
    city: yup.string().nullable(),
    state: yup.string().nullable(),
    zipCode: yup.string().nullable(),
    country: yup.object().nullable(),
  })
  .required();

const currencyOptions: any = [
  { label: 'USD $', value: 'USD' },
  { label: 'EUR €', value: 'EUR' },
];

export const CreateClientForm = ({ changeStep }: any) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    defaultValues: { country: { value: 'United States', label: 'United States', code: 'US' } },
  });

  const [countryCode, setCountryCode] = useState<any>('US');

  const onSubmit = (data: IFormInputs) => {
    changeStep(STEPS.COMPANY_CREATE, STEPS.CONTACT_CREATE, data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* Company */}
      <InputDiv>
        <InputLabel>Company</InputLabel>
        <input {...register('company')} />
        {errors?.company && <InputError>{errors.company.message}</InputError>}
      </InputDiv>

      {/* VAT Number */}
      {/* <InputDiv>
        <InputLabel>VAT Number</InputLabel>
        <input {...register('vatNumber')} />
        {errors?.vatNumber && <InputError>{errors.vatNumber.message}</InputError>}
      </InputDiv> */}

      {/* Country */}
      <InputDiv>
        <InputLabel>Country</InputLabel>
        <Controller
          name="country"
          control={control}
          render={({ field }) => (
            <ReactSelect
              isClearable
              {...field}
              options={countryOptions}
              onChange={(value: any) => {
                field.onChange(value);
                setCountryCode(value?.code);
              }}
            />
          )}
        />
        {errors?.country && <InputError>{errors.country.message}</InputError>}
      </InputDiv>

      {/* Phone Number */}
      {/* <InputDiv>
        <InputLabel>Phone</InputLabel>
        <input {...register('phoneNumber')} />
        {errors?.phoneNumber && <InputError>{errors.phoneNumber.message}</InputError>}
      </InputDiv> */}
      <InputDiv>
        <InputLabel>Phone</InputLabel>
        <Controller
          name="phoneNumber"
          control={control}
          render={({ field }) => (
            <PhoneInput
              {...field}
              international
              defaultCountry={countryCode}
              // onChange={(value) => {
              //   console.log(value);
              // }}
              // disabled={props.disabled}
            />
          )}
        />
        {errors?.country && <InputError>{errors.country.message}</InputError>}
      </InputDiv>

      {/* Website */}
      <InputDiv>
        <InputLabel>Website</InputLabel>
        <input {...register('website')} />
        {errors?.website && <InputError>{errors.website.message}</InputError>}
      </InputDiv>

      {/* Currency */}
      {/* <InputDiv>
        <InputLabel>Currency</InputLabel>
        <Controller
          name="currency"
          control={control}
          render={({ field }) => <ReactSelect isClearable {...field} options={currencyOptions} />}
        />
        {errors?.currency && <InputError>{errors.currency.message}</InputError>}
      </InputDiv> */}

      {/* Address */}
      <InputDiv>
        <InputLabel>Address</InputLabel>
        <Controller
          name="address"
          control={control}
          render={({ field }) => <textarea {...field} rows={3}></textarea>}
        />
        {errors?.address && <InputError>{errors.address.message}</InputError>}
      </InputDiv>

      {/* City */}
      <InputDiv>
        <InputLabel>City</InputLabel>
        <input {...register('city')} />
        {errors?.city && <InputError>{errors.city.message}</InputError>}
      </InputDiv>

      {/* State */}
      <InputDiv>
        <InputLabel>State</InputLabel>
        <input {...register('state')} />
        {errors?.state && <InputError>{errors.state.message}</InputError>}
      </InputDiv>

      {/* Zip Code */}
      <InputDiv>
        <InputLabel>Zip Code</InputLabel>
        <input {...register('zipCode')} />
        {errors?.zipCode && <InputError>{errors.zipCode.message}</InputError>}
      </InputDiv>

      <div className="row">
        <div className="column-2"></div>
        <div className="column-2">
          <input type="submit" value="Next" />
        </div>
      </div>
    </form>
  );
};

const InputDiv = styled.div`
  padding: 5px;
`;

const InputLabel = styled.p`
  color: black;
  margin-top: 8px;
  margin-bottom: 1px;
  font-weight: 500;
  font-size: 15px;
`;

const InputError = styled.p`
  color: red;
  margin-top: 0px;
  margin-bottom: 0px;
  &:before {
    display: inline;
    content: '⚠ ';
  }
`;
